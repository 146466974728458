@import '../../../Shared/Styles/Metal/Metal.scss';

.app-info-panel {
    @include metal-panel;

    overflow: hidden;

    .scrollable-content {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }

    .info-link {
        width: 27vh;
        height: 10vh;
        display: block;
        margin: 0 auto;
    }



    /* Maximum aspect ratio */
    @media (orientation: landscape) {
        h1 {
            line-height: 18vh;
            font-size: 12vh;
            word-spacing: 1.4vh;
            height: 13vh;
        }

        .scrollable-content {
            height: calc(100% - 13vh);
        }

        line-height: 9vh;
        font-size: 6vh;
        word-spacing: 0.7vh;
        padding: 2vh 4vh 4vh 4vh;
    }

    /* Maximum aspect ratio */
    @media (max-aspect-ratio: 7/5) {
        h1 {
            line-height: 18vh;
            font-size: 12vh;
            word-spacing: 1.4vh;
            height: 13vh;
        }

        .scrollable-content {
            height: calc(100% - 13vh);
        }

        line-height: 9vh;
        font-size: 6vh;
        word-spacing: 0.7vh;
        padding: 2vh 4vh 4vh 4vh;
    }

    /* Maximum aspect ratio */
    @media (max-aspect-ratio: 1/1) {
        h1 {
            line-height: 17vw;
            font-size: 10vw;
            word-spacing: 1.2vh;
            height: 13vw;
        }

        .scrollable-content {
            height: calc(100% - 13vw);
        }

        line-height: 9vw;
        font-size: 6vw;
        word-spacing: 0.7vh;
        padding: 2vw 4vw 4vw 4vw;
    }

    /* Maximum aspect ratio */
    @media (max-aspect-ratio: 2/3) {
        h1 {
            line-height: 28vw;
            font-size: 16vw;
            word-spacing: 2vh;
            height: 20vw;
        }

        .scrollable-content {
            height: calc(100% - 20vw);
        }

        line-height: 12vw;
        font-size: 8vw;
        word-spacing: 1vh;
        padding: 2vw 4vw 4vw 4vw;
    }
}