.custom-link-container {
    height: 6.5vh;
    width: 100%;

    .custom-link {
        height: 100%;
        display: flex;
        align-items: center;

        span {
            white-space: nowrap;
        }
    }
}