@import '../Button/Button.scss';

@mixin metal-border {
    border: 1px solid;
    /* this is a link from the location of components sub folders */
    -webkit-border-image: url('../../../Shared/Styles/Metal/Images/MetalBorder.png');
    /* Safari from 3.1 to 5 */
    -o-border-image: url('../../../Shared/Styles/Metal/Images/MetalBorder.png');
    /* Opera from 11 to 12.1 */
    border-image: url('../../../Shared/Styles/Metal/Images/MetalBorder.png');
    border-image-slice: 3;
    border-image-width: 1.3vh;
    border-image-outset: 0.75vh;
    border-image-repeat: stretch;
    margin: 0.75vh;
    width: calc(100% - 1.5vh);
    height: calc(100% - 1.5vh);
}

@mixin metal-background {
    /* this is a link from the location of components sub folders */
    background-image: url('../../../Shared/Styles/Metal/Images/RepeatingMetal.png');
    background-size: 25vh;
    background-repeat: repeat;
    image-rendering: pixelated;
}

@mixin metal-button {
    @include metal-border;
    @include metal-background;
    @include button;
}

@mixin metal-panel {
    @include metal-border;
    @include metal-background;

    span {
        @include button-text;
    }
}