@font-face {
    font-family: 'RockBottomGames';
    src: url('./RockBottomGames/RockBottomGames-SVG-CBDT.woff2') format('woff2'),
        url('./RockBottomGames/RockBottomGames-SVG-CBDT.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-size: small;
    descent-override: 25%;
    ascent-override: 25%;
}

html body {
    font-family: RockBottomGames, Arial, serif;
}