@import '../../../Shared/Styles/Metal/Metal.scss';

.metalLink {
    @include metal-button;

    &:hover,
    &:focus {
        filter: brightness(1.2);
    }

    &:active,
    &.active-link {
        padding-left: 0.5vh;
        padding-top: 0.5vh;

        span {
            text-shadow: none;
        }
    }
}