@import '../../../Shared/Styles/Dirt/Dirt.scss';

.dirtLink {
    @include dirt-button;

    &:hover,
    &:focus {
        filter: brightness(1.2);
    }

    &:active,
    &.active-link {
        padding-left: 0.5vh;
        padding-top: 0.5vh;

        span {
            text-shadow: none;
        }
    }
}