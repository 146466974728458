@import '../Button/Button.scss';

@mixin dirt-border {
    border: 1px solid;
    /* this is a link from the location of components sub folders */
    -webkit-border-image: url('../../../Shared/Styles/Dirt/Images/DirtBorder.png');
    /* Safari from 3.1 to 5 */
    -o-border-image: url('../../../Shared/Styles/Dirt/Images/DirtBorder.png');
    /* Opera from 11 to 12.1 */
    border-image: url('../../../Shared/Styles/Dirt/Images/DirtBorder.png');
    border-image-slice: 10 4 4 4;
    border-image-width: 4vh 1.3vh 1.3vh 1.3vh;
    border-image-repeat: none;
    border-image-outset: 2vh 0.75vh 0.75vh 0.75vh;
    margin: 2vh 0.75vh 0.75vh 0.75vh;
    width: calc(100% - 1.5vh);
    height: calc(100% - 2.75vh);
}

@mixin dirt-background {
    /* this is a link from the location of components sub folders */
    background-image: url('../../../Shared/Styles/Dirt/Images/RepeatingDirt.png');
    background-size: 25vh;
    background-repeat: repeat;
    image-rendering: pixelated;
}

@mixin dirt-button {
    @include dirt-border;
    @include dirt-background;
    @include button;
}