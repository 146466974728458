@mixin button {
    span {
        @include button-text;
    }

    display: inline-flex;
    flex-direction: column;
    backface-visibility: hidden;
    vertical-align: text-top;
    transform: translateZ(0);
    padding-left: 0vh;
    padding-top: 0vh;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

@mixin button-text {
    @supports (-webkit-touch-callout: none) {
        margin-top: -1.5vh;
    }

    @supports not (-webkit-touch-callout: none) {
        text-shadow: 0.5vh 0.5vh rgba(0, 0, 0, 0.25);
    }

    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 10vh;
    font-size: 6.5vh;
    word-spacing: 0.7vh;
    word-break: break-all;
    height: 10vh;
}