.app-links {
    height: 50%;

    .app-link {
        height: 24%;
    }
}

.app-links-short {
    height: 10%;

    .app-link {
        height: 100%;
    }
}

.app-links-pagination {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-areas:
        "prev home next";
    justify-items: center;

    .app-link-prev {
        grid-area: prev;
        width: 100%;
    }

    .app-link-home {
        grid-area: home;
        width: 23vh;
    }

    .app-link-next {
        grid-area: next;
        width: 100%;
    }
}

.app-links-list {
    display: flex;
    align-items: center;
    flex-direction: column;

    .app-link {
        width: 23vh;
    }
}