$off-left: -100%;
$shown: 0%;
$off-right: 100%;

$home-page: ".home-page";

$info-panel-page-in: ".info-panel-page";

$info-panel-page-out: ".info-panel-page-out";

$all-pages: $info-panel-page-in, $info-panel-page-out, $home-page;


@mixin slide-animation-mixin($name, $time) {
    animation: $name $time forwards;
    -webkit-animation: $name $time forwards;
}

@mixin slide-position-mixin($position) {
    transform: translateX($position);
    -webkit-transform: translateX($position);
}

@mixin slide-mixin($name, $from, $to) {
    @-webkit-keyframes #{$name} {
        0% {
            @include slide-position-mixin($from);
        }

        100% {
            @include slide-position-mixin($to);
        }
    }

    @keyframes #{$name} {
        0% {
            @include slide-position-mixin($from);
        }

        100% {
            @include slide-position-mixin($to);
        }
    }
}

@mixin page-animations-mixin($time) {
    /* CSS Transitions */

    // Position while page is active
    .page {
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        #{$all-pages} {
            position: absolute;
            width: 100%;
            @include slide-position-mixin($shown);
        }
    }

    // Initial position before enter
    .page-enter {

        #{$home-page},
        #{$info-panel-page-out} {
            @include slide-position-mixin($off-left);
        }

        #{$info-panel-page-in} {
            @include slide-position-mixin($off-right);
        }
    }

    .page-enter-active {

        #{$home-page},
        #{$info-panel-page-out} {
            @include slide-animation-mixin(slide-in-right, $time);
        }

        #{$info-panel-page-in} {
            @include slide-animation-mixin(slide-in-left, $time);
        }
    }

    // Initial position before exit
    .page-exit {
        #{$all-pages} {
            @include slide-position-mixin($shown);
        }
    }

    .page-exit-active {

        #{$home-page},
        #{$info-panel-page-out} {
            @include slide-animation-mixin(slide-out-left, $time);
        }

        #{$info-panel-page-in} {
            @include slide-animation-mixin(slide-out-right, $time);
        }
    }
}

.app-root {
    height: 100%;
    width: 100%;
    background-image: url('../Shared/Images/RepeatingBackground.png');
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: center;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    overflow-x: hidden;

    .app {
        background-image: url('../Shared/Images/Background.png');
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        image-rendering: pixelated;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
        height: 100%;
        overflow-x: hidden;
        text-align: center;

        .app-title {
            height: 40%;
        }
    }
}

@include page-animations-mixin(0.3s);

/* Maximum aspect ratio */
@media (max-aspect-ratio: 1/1) {
    @include page-animations-mixin(0.23s);
}

/* Maximum aspect ratio */
@media (max-aspect-ratio: 1/2) {
    @include page-animations-mixin(0.17s);
}

@include slide-mixin(slide-in-right, $off-left, $shown);
@include slide-mixin(slide-out-right, $shown, $off-right);
@include slide-mixin(slide-in-left, $off-right, $shown);
@include slide-mixin(slide-out-left, $shown, $off-left);