.title {
    text-align: center;
    height: 100%;
}

.title img {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    height: 100%;
    display: inline-block;
    margin: 0 auto;
}