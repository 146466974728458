.info-panel-page,
.info-panel-page-out {
    width: 100%;
    height: 100%;
    @import '../../../Shared/Styles/Metal/Metal.scss';

    .info-panel-page-centered-container {
        height: 100%;
        display: block;
        margin: 0px auto;

        /* Maximum aspect ratio */
        @media (orientation: landscape) {
            width: 50%;
        }

        /* Maximum aspect ratio */
        @media (max-aspect-ratio: 7/5) {
            width: 80%;
        }

        /* Maximum aspect ratio */
        @media (max-aspect-ratio: 1/1) {
            width: 90%;
        }

        /* Maximum aspect ratio */
        @media (max-aspect-ratio: 2/3) {
            width: 90%;
        }

        .info-panel-page-content {
            height: 75%;
            width: 100%;
        }
    }
}